import React from 'react';
import { graphql } from 'gatsby';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import Link from 'components/Link';
import useSiteMetadata from 'hooks/useSiteMetadata';
import supportedSites from 'data/supported-sites.json';
import knownSites from 'data/sites.json';

// Sites grouped by letters
const groupedSites = supportedSites.reduce((obj, name) => {
  const letter = name[0].toLowerCase();
  let group;

  // Check the letter is a number
  if (isNaN(Number(letter))) {
    group = letter;
  } else {
    group = '#';
  }

  // Create the group if it doesn't exist
  if (!obj[group]) {
    obj[group] = [];
  }

  obj[group].push(name);

  return obj;
}, {});

function siteLink(name) {
  const slug = name.replace(/[\s+.]/g, '-').toLowerCase();

  if (knownSites[slug]) {
    return `/${slug}-downloader`;
  }

  return '/downloader';
}

export default function SitesPage() {
  const { t } = useTranslation();

  const site = useSiteMetadata();
  const title = t('sites:meta.title');

  const [searchText, setSearchText] = React.useState('');
  const [searchResult, setResult] = React.useState([]);

  React.useEffect(() => {
    // Search query lowercased and trimmed
    const query = searchText.toLowerCase().trim();

    if (query) {
      // Create a list of search keywords
      const queryList = query.split(' ');

      const result = supportedSites.filter((name) => {
        // The entry matches if it contains all the keywords
        return queryList.every((keyword) => {
          return name.toLowerCase().includes(keyword);
        });
      });

      setResult(result);
    } else {
      setResult([]);
    }
  }, [searchText]);

  const hasResult = searchResult.length > 0;

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('sites:meta.desc')}
        keywords={title}
      />

      <Section>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h1>
            <p className="mt-4 text-gray-500">{t('sites:subtitle')}</p>
          </div>

          <div className="mt-12">
            <input
              type="text"
              className="w-full px-5 py-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              placeholder="Search the supported sites"
              onChange={(e) => setSearchText(e.target.value)}
            />

            {hasResult ? (
              <div className="mt-6 border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Search Results
                  </h2>
                </div>

                <div className="pt-6 pb-8 px-6">
                  <ul className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                    {searchResult.map((site) => (
                      <li key={site} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <Link
                          href={siteLink(site)}
                          className="text-sm text-gray-500 hover:underline"
                        >
                          {site}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : searchText ? (
              <div className="mt-6 rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      {t('sites:not_found')}
                      <br />
                      {t('sites:not_all_supported')}
                    </p>
                    <p className="mt-3 text-sm md:mt-0 md:ml-6">
                      <Link
                        href="/downloader"
                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                      >
                        {t('common:try_now')}{' '}
                        <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
            {Object.entries(groupedSites).map(([group, sites]) => (
              <div
                key={group}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {group.toUpperCase()}
                  </h2>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <ul className="space-y-4">
                    {sites.map((site) => (
                      <li key={site} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <Link
                          href={siteLink(site)}
                          className="text-sm text-gray-500 hover:underline"
                        >
                          {site}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "sites", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
